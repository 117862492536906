

import React, { useEffect, useState } from "react";
import logo from "assets/img/Logo_Coopap.jpg";
import { Card, Row } from "react-bootstrap";
import styles from "./Links.module.scss";

const linksDummy = [
    { id: 1, title: "Sitio web", to: 'https://coopap.gt', icon: styles['svg-internet'], color: styles.primary },
    { id: 2, title: "Instagram", to: 'https://www.instagram.com/coopap.gt/', icon: styles['svg-instagram'], color: styles.primary },
    { id: 3, title: "Nuestra ubicación", to: 'https://maps.app.goo.gl/kJb2xf6YVSs8NsSi8', icon: styles['svg-location'], color: styles.primary },
    { id: 4, title: "WhatsApp", to: 'https://wa.me/50230802510?text=Hola Coopap, me gustaría más información de sus préstamos hipotecarios', icon: "fab fa-whatsapp", color: styles.primary },
    { id: 5, title: "Teléfono", to: 'tel:+50230802510', icon: styles['svg-phone'], color: styles.primary },
    { id: 6, title: "Asesor-IA", to: 'https://wa.me/17373177061?text=Hola, me gustaría su asesoría con mis dudas financieras', icon: styles['svg-user'], color: styles.primary },
]

function Links(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [])

    useEffect(() => {
        // Create meta elements
        const cacheControlMeta = document.createElement('meta');
        cacheControlMeta.httpEquiv = 'cache-control';
        cacheControlMeta.content = 'no-cache';

        const expiresMeta = document.createElement('meta');
        expiresMeta.httpEquiv = 'expires';
        expiresMeta.content = '0';

        const pragmaMeta = document.createElement('meta');
        pragmaMeta.httpEquiv = 'pragma';
        pragmaMeta.content = 'no-cache';

        // Add meta elements to the document head
        document.head.appendChild(cacheControlMeta);
        document.head.appendChild(expiresMeta);
        document.head.appendChild(pragmaMeta);

        // Cleanup: remove meta elements when the component unmounts
        return () => {
            document.head.removeChild(cacheControlMeta);
            document.head.removeChild(expiresMeta);
            document.head.removeChild(pragmaMeta);
        };
    }, []);

    return (
        <Card className="card text-center p-4" style={{ width: "22rem", backgroundColor: "#EEE242", borderRadius: "15px" }}>
            {/* Logo */}
            <Row className="mb-4 d-flex justify-content-center align-items-center">
                <img
                    src={logo} // Replace with your logo path
                    alt="Cooperativa Logo"
                    className="rounded-circle"
                    style={{ width: "150px", height: "auto" }}
                />
            </Row>
            {/* Title and Subtitle */}
            <Card.Title className={styles.title}>Cooperativa</Card.Title>
            <Card.Subtitle className={"mb-3 " + styles.subtitle}>Cooperativa de ahorro y crédito.</Card.Subtitle>
            {/* WhatsApp Icon */}
            {/* Buttons */}
            <Row className="d-grid gap-2">
                {linksDummy.map(link => (
                    <a key={link.id} href={link.to} className={styles['no-style-link'] + styles.button + " btn btn-primary "} style={{ background: link.color }}>
                        {
                            link.icon ?
                                <p className="placeholder-wave m-0 p-0">
                                    <div className={"d-flex justify-content-center align-items-center " + (loading ? 'placeholder placeholder-sm' : '')}>
                                        <i className={link.icon + " " + styles.icon + " " + styles.svg}></i>
                                        <div className={styles['button-text']}>
                                            {link.title}
                                        </div>
                                    </div>
                                </p>
                                :
                                <div>{link.title}</div>
                        }
                    </a>
                ))}
            </Row>
        </Card>
    );
}

export default Links;