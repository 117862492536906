import React from "react";
import logo from "assets/img/Logo_Coopap_Icono.jpg";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import styles from "./CentralElementLayout.module.scss";

function CentralElementLayout(props) {
    return (
        <div className={"d-flex flex-column min-vh-100 " + styles['main-container']}>
            {/* Yellow Background */}
            <Container className={"d-flex justify-content-center mt-5"}>
                {props.children}
            </Container>

            {/* Footer */}
            <footer className={styles.footer + " text-center p-3 mt-auto"}>
                <p className="text-white mb-0">© 2024 Coopap. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
}

export default CentralElementLayout;
/* import React, { useEffect, useState } from "react";
import styles from './CentralElementLayout.module.scss';
import { Navigate } from "react-router-dom";

export default function CentralElementLayout(props) {
    const { classes, redirectAfterTo, timeOutToRedirect } = props;
    const [redirectNow, setRedirectNow] = useState(false);
    let extraClassNames = "ce-layout-wrapper ";

    useEffect(() => {
        if (redirectAfterTo)
            if (timeOutToRedirect) setTimeout(() => setRedirectNow(true), timeOutToRedirect);
            else setTimeout(() => setRedirectNow(true), 5000);
    });

    if (classes instanceof Array) {
        extraClassNames += classes.join(" ");
    }
    if (redirectAfterTo && redirectNow) return <Navigate to={redirectAfterTo} replace />;

    return <div className={styles.layoutwrapper + " " + extraClassNames}>
        <div className={styles.centralelement + " ce-central-element "} >
            {props.children}
        </div>
    </div>;
}; */