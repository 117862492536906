import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'styles/theme.scss';
import CentralElementLayout from 'common/layouts/CentralElementLayout/CentralElementLayout';
import Links from 'modules/links/Links';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <CentralElementLayout>
            <Links />
        </CentralElementLayout>
    </React.StrictMode>
);

/* sass.renderSync({
    silenceDeprecations: ['legacy-js-api'],
}); */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
